/* About Page CSS */

.about_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.intro_container {
   position: relative;
   background-color: rgb(234, 237, 240, 0.8);
   padding: 2rem 2rem;
   margin-top: 5%;
   min-width: min(800px, 90vw);
}

.intro {
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
}

#intro_video {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (min-aspect-ratio: 16/9) {
    #intro_video {
        width:100%;
        height: auto;
    }
}
@media (max-aspect-ratio: 16/9) {
    #intro_video { 
        width: auto;
        height: 100%;
    }
}

.about_photo {
    max-height: 25rem;
    max-width: 25rem;
    margin-right: 2rem;
}

.social {
   display: flex;
   justify-content: center;
}

.social > i {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

}

.social > i:hover {
    cursor: pointer;
    color: red;
}

hr {
    clear:both;
    display:block;
    width: 96%;               
    height: 1px;
}

.sub_intro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Project Page CSS */